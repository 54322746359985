import React, { FunctionComponent } from 'react'; /* explicitly for axe-core */
import '../styles/globals.css';
import '../styles/pricingv2-styles.css';
import { SSRProvider } from '@react-aria/ssr';

import SegmentLayout from '../layouts/SegmentLayout';
import ErrorBoundary from '@layouts/ErrorBoundary';
import type { AppProps } from 'next/app';

const runAxe = async (): Promise<void> => {
  const ReactDOM = await import('react-dom');
  const axe = await import('@axe-core/react');
  axe.default(React, ReactDOM, 1000);
};

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  runAxe();
}

// The _app.js file is special for Next.js. It's what is
// used to initialize pages. It can be overridden for some
// added functionality.
// For more info see: https://nextjs.org/docs/advanced-features/custom-app
const MyApp: FunctionComponent<AppProps> = ({ Component, pageProps }) => (
  <ErrorBoundary>
    <SSRProvider>
      <SegmentLayout>
        <Component {...pageProps} />
      </SegmentLayout>
    </SSRProvider>
  </ErrorBoundary>
);

export default MyApp;
