function isGAPropertyObject(val: unknown): val is GAPropertyObject {
  return typeof val !== 'function';
}

/**
 * Retrieves object of current active Google Optimize experiments after waiting
 * for Google Analytics to be initialized
 * @returns Promise resolving to object where keys are experiment IDs and values
 * are assigned variants
 */
export const getOptimizeExperiments = (
  maxRetries = 50
): Promise<OptimizeExperimentMap> =>
  new Promise((resolve) => {
    let retries = 0;

    const poll = setInterval(() => {
      if (window.gaData) {
        clearInterval(poll);
        // window.gaData can have a tracker_created function
        const analyticsProperty = Object.values(window.gaData).find(
          isGAPropertyObject
        );
        const { experiments = {} } = analyticsProperty || {};
        return resolve(experiments);
      }

      if (retries >= maxRetries) {
        clearInterval(poll);
        return resolve({});
      }

      retries++;
    }, 100);
  });
