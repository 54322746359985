import { Component, ErrorInfo, ReactNode } from 'react';
import { RaygunV2 } from 'raygun4js';

interface Props {
  children: ReactNode;
}
interface State {
  hasError: boolean;
}
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rg4js: RaygunV2 = (window as any).rg4js;
    if (rg4js) {
      rg4js('send', {
        error: error,
        customData: errorInfo
      });
    }
    else {
      // eslint-disable-next-line no-console
      console.error('Uncaught error:', error, errorInfo);
    }
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return <h1>Sorry.. there was an error</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
