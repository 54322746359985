import { ReactNode, useEffect } from 'react';
import { getOptimizeExperiments } from '@lib/utils/getOptimizeExperiments';

const SegmentLayout = ({ children }: { children?: ReactNode }): JSX.Element => {
  useEffect(() => {
    const trackPage = async () => {
      const activeExperiments = await getOptimizeExperiments();

      window.analytics.page({
        activeExperiments,
      });
    };

    trackPage();
  }, []);

  return <>{children}</>;
};

export default SegmentLayout;
